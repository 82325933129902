<template>
  <div>
    <v-btn
      @click="$emit('activateComponent', cardData.id)"
      width="100%"
      height="50"
      class="ma-2"
      outlined
      :color="cardData.color ? cardData.color : isActive ? 'orange' : 'black'"
      style="
        text-transform: initial;
        display: flex;
        justify-content: space-between;
      "
    >
      <span class="font-weight-bold font-size-16"
        ><font-awesome-icon
          :icon="cardData.icon"
          class="m-r-20 font-size-20"
        ></font-awesome-icon
        >{{ cardData.title }}</span
      >
      <font-awesome-icon
        :icon="isActive ? faChevronUp : faChevronDown"
      ></font-awesome-icon>
    </v-btn>
    <slot name="cardForm"></slot>
  </div>
</template>

<script>
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
export default {
  props: {
    cardData: {
      type: Object,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faChevronDown,
      faChevronUp,
    };
  },
};
</script>

<style></style>
